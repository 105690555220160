import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../../components/Core";
import { device } from "../../../utils";
import MainImage from '../../../assets/image/jpeg/active-acne.jpg';


const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;


const Newbut = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: auto;
margin-bottom: 4em;
padding-top: 1em;
padding-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
opacity: 0.4;

color: #fff;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
max-width: 100%;
    display: inline-block;
`;




const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #000;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 0px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;



const Secti = styled.section`


position: relative;




@media screen and (max-width: 767px)
{
  padding-top:1em;
  padding-bottom:0px;
  margin-bottom:0px;
 }

`;




const Sect = styled.section`

  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;

const BAcnffeCauddsesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
    <Section bg="#ffffff" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
          <Iwrap>

          
          <Itext as="h2">Forehead Lines London
</Itext> 
          </Iwrap>
                <SecondText>
                Causes of Forehead Lines
                </SecondText>

               
           
              </div>
            </div>
          
          </Col>

         
        </Row>

        <Row className="align-items-center">
            <Col lg="12">
            <Text>
            A multitude of factors can generate forehead lines, often known as expression lines or worry lines.
 <br /> <br />

 Facial emotions, such as frowning, squinting, or raising the brows, can cause the muscles of the forehead to contract and form lines.
 <br /> <br />
 
 Ageing: As we get older, our skin loses collagen and elasticity, which can lead to wrinkles and creases.
 <br /> <br />
 
 Sun damage: Sun exposure can cause skin damage and premature ageing, including the formation of forehead wrinkles.
 <br /> <br />
 
 Smoking can hasten the ageing process and contribute to the formation of wrinkles and lines.
 <br /> <br />
 
 Genetics can play a part in the development of forehead lines because some people are susceptible to developing wrinkles and lines at a younger age.
 <br /> <br />
 
 Dehydration: Dehydration can cause the skin to appear dry and more prone to wrinkles and lines.
 <br /> <br />
 
 Poor nutrition: A diet deficient in nutrients, especially antioxidants and good fats, can lead to rapid ageing and the formation of wrinkles and creases.
 <br /> <br />
 
 While forehead lines are an unavoidable part of the ageing process, some lifestyle adjustments, such as wearing sunscreen, avoiding smoking, and staying hydrated, may assist to minimise their appearance. Botox injections and dermal fillers can also assist to minimise the appearance of forehead wrinkles. <br /> <br />
               </Text>
            </Col>

        </Row>

        <Row>
            <Col lg="12">
              <Text>
              Crow's feet are a cosmetic concern defined by the appearance of fine lines and wrinkles around the corners of the eyes rather than a medical condition with particular symptoms. When we smile, squint, or make other facial gestures that involve the muscles surrounding our eyes, these lines become more visible. <br /> <br />

 

Crow's feet can be mild or severe, appearing as thin, shallow lines or deep creases extending from the corners of the eyes to the temples. 
</Text>
            </Col>
        </Row>
       
   
      </Container>

    
     
    </Section>
  
    
  </>
);

export default BAcnffeCauddsesPage;
